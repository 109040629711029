const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://botfront.digtlab.ru',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://ftp.digt.ru/web/static/test.png',
    logoLink: 'https://botfront.digtlab.ru',
    title: '',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: `<li>
		    <a href="" target="_blank" rel="noopener">
		      <div class="twitterBtn">
		        <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/twitter-brands-block.svg' alt={'Twitter'}/>
		      </div>
		    </a>
		  </li>
			<li>
		    <a href="" target="_blank" rel="noopener">
		      <div class="discordBtn">
		        <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/discord-brands-block.svg' alt={'Discord'}/>
		      </div>
		    </a>
		  </li>`,
    links: [{
      text: '',
      link: ''
    }],
//    search: {
//      enabled: true,
//      indexName: 'wisebot',
//      algoliaAppId: "YSUPZ6O4QF",
//      algoliaSearchKey: "a9c5865fedb6fa111eba7be1fffb6ac8",
//      algoliaAdminKey: "856f676628f9f739513bb0757591b2cc",
//    },
  },
  sidebar: {
    forcedNavOrder: ['01-v1.0','02-v1.0','03-v1.0','04-v1.0','05-v1.0'],
    collapsedNav: [],
    links: [{
      text: 'Wisebot',
      link: 'https://botfront.digtlab.ru'
    }],
    frontline: false,
    ignoreIndex: true,
    title: "<a href='https://botfront.digtlab.ru'>Wisebot </a>",
  },
  siteMetadata: {
    title: 'GitDocs Wisebot',
    description: 'Documentation built with mdx.',
    ogImage: null,
    docsLocation: '',
    favicon: 'https://ftp.digt.ru/web/static/wisebot_logo.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'GitDocs Wisebot',
      short_name: 'GitDocs Wisebot',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [{
        src: 'src/pwa-512.png',
        sizes: `512x512`,
        type: `image/png`,
      }, ],
    },
  },
};

module.exports = config;
